export const READER_ENV = (window.location.origin.split("").reverse().join("") === "oc.moc.ekamemak.rotcelnalp//:sptth" || window.location.origin === 'https://planlector.makemake.com.co')?'production':'development';
/**
 * Prefijo que se pone anterior al nombre del evento que se envia como mensaje a esta ventana o al contenedor de este lector.
 */
export const READER_MESSAGE_EVENT_PREFIX = "MAKEMAKE_READER_EVENT:";
/**
 * Nombre de los eventos disponibles para enviar.
 */
export const READER_MESSAGE_EVENTS_TO_RECEIVE = {
	ASYNC_STATS: "ASYNC_STATS",
	ACTIVITY_CLICK: "ACTIVITY_CLICK",
	SHARE_CLICK: "SHARE_CLICK",
};
/**
 * Nombre de los eventos disponibles para enviar.
 */
export const READER_MESSAGE_EVENTS_TO_SEND = {
	DOWNLOADED_IN_APP: "DOWNLOADED_IN_APP",
	NETWORK_STATUS_CHANGES:'NETWORK_STATUS_CHANGES'
};

/**
 * Tiempo de refresco de las consultas de react-query (15min)
 */
export const DEFAULT_QUERIES_STALE_TIME = 60 * 15000;
import { useQuery } from "@tanstack/react-query";
import generalCallApi from "../../containers/helpers/generalCallApi";
import { DEFAULT_QUERIES_STALE_TIME } from "../../config/constants";

export const useTeachersQuery = (props = {}) => {
	const { enabled = true } = props;
	const query = useQuery(["/profesores/obtenerListadoProfesores"], {
		staleTime: DEFAULT_QUERIES_STALE_TIME,
		refetchOnWindowFocus: false,
		queryFn: () =>
			generalCallApi({
				pathname: "/profesores/obtenerListadoProfesores",
			}).then((res) => {
				return res.data;
			}),
		enabled,
	});

	const getAllasArray = ()=>{
		const data = query?.data;
		if (Array.isArray(data) && data.length <= 0) {
			return [];
		}
		let profesores = [];

		data.forEach(profesor=>{
			const objetoCursos = Object.values(profesor?.cursos);
			const idSedes = Object.keys(profesor?.cursos);
			const cursos = [];
			objetoCursos.forEach((sede,i)=>{
				return sede?.cursos.forEach(curso=>{
					cursos.push({...curso, idSede:idSedes[i],nombreSede:sede.nombre })
				})
			})
			profesores.push(
				{...profesor, cursos:cursos}
			)
		})
		return profesores;
	}
	const getByEmail= ({email})=>{
  			const profesores = getAllasArray();
		   const coincidencias = profesores.filter(profesor=>profesor?.correo === email);
			const profesor = coincidencias.length > 0? coincidencias[0]:undefined;
			return profesor;
	}

	return { ...query, getAllasArray, getByEmail };
};

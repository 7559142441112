import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Componentes
import VerificarInstitutoComponent from "../../components/institutos/VerificarInstituto/";
//Funciones
import generalCallApi from './../helpers/generalCallApi';
//Store
import { useTrackedState, useDispatch } from '../../store';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { useInstitutionQuery } from '../../hooks/api-queries/use-institution-query';

/**
 * Container que recibe como prop la función de descartarCambios. Este container renderiza el componente VerificarInstitutoComponent al cual se le pasan una serie de propiedades que muestran la pantalla de verificar campos. Las principales funciones de este componente son actualizar los datos temporales y crear el instituto/colegio.
 * @returns {JSX.Element} VerificarInstitutoComponent
 */
const VerificarInstituto = (props) => {
  const { descartarCambios } = props;
  //Estados globales
  const state = useTrackedState();
  const { temporal } = state;
  const institutionQuery = useInstitutionQuery();
  const dispatch = useDispatch();
  const  queryClient = useQueryClient();

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('instituto', false, 'verificar_datos')

  const [preloaderInstituto, setPreloaderInstituto] = useState(false)
  const [datosSede, setDatosSede] = useState({})

  const [respuestaApiCrearSede, setRespuestaApiCrearSede] = useState({
    isError: null,
    mensaje: ''
  })

  const history = useHistory()

  //actualiza los datos temporales 
  useEffect(() => {
    if (!!temporal?.sede) {
      let cursos = [];
      let profesores = [];
      let estudiantes = [];

      const datosSedeTemporales = temporal.sede;

      datosSedeTemporales.profesores.map(profesor => profesores.push({ ...profesor, cursos: profesor.cursos.join(', ') }))

      datosSedeTemporales.cursos.map(curso => {
        !!curso.estudiantes && curso.estudiantes.map(estudiante => {
          estudiantes.push({
            ...estudiante,
            curso: curso.nombre,
          })
          return estudiante;
        })

        const profesoresCurso = [];
        !!curso.profesores && curso.profesores.map(profesor => profesoresCurso.push(profesor.nombre));


        let cursoNuevo = {
          ...curso,
          numEstudiantes: !!curso.estudiantes ? curso.estudiantes.length : 0,
          profesor: !!curso.profesores ? profesoresCurso.join(', ') : null
        }
        delete cursoNuevo.estudiantes;
        delete cursoNuevo.profesores;

        cursos.push(cursoNuevo)
        return curso;
      })

      setDatosSede({
        color: datosSedeTemporales.color,
        correo: datosSedeTemporales.correo,
        cursos: cursos,
        estudiantes: estudiantes,
        nombre: datosSedeTemporales.nombre,
        paquete: datosSedeTemporales.paquete,
        profesores: profesores,
        telefono: datosSedeTemporales.telefono,
      })

    } else {
      history.push('/institutos')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal])


  //función que crea el instituto/colegio
  const crearInstituto = async () => {
    if (!preloaderInstituto && respuestaApiCrearSede.isError !== false) {
      setRespuestaApiCrearSede({
        isError: null,
        mensaje: ''
      });

      setPreloaderInstituto(true);

      const pathname = '/sedes/crearSede';
      const datosSede = temporal.sede;
      const properties = {
        color: datosSede.color,
        correo: datosSede.correo,
        cursos: datosSede.cursos,
        nombre: datosSede.nombre,
        paquete: datosSede.paquete.nombre,
        profesores: datosSede.profesores,
        telefono: datosSede.telefono,
      }

      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        queryClient.invalidateQueries({ queryKey: ["/sedes/obtenerInstituto"], });  
        queryClient.invalidateQueries({ queryKey: ["/cursos/obtenerCursos"], refetchType:'none' });
        
      }

      setRespuestaApiCrearSede({
        isError: error,
        mensaje: result.info
      });

      setPreloaderInstituto(false);
    }
  }

  return (
    <>
      {
        isReady &&
        <VerificarInstitutoComponent
          crearInstituto={crearInstituto}
          datosSede={datosSede}
          descartarCambios={descartarCambios}
          preloaderInstituto={preloaderInstituto}
          respuestaApiCrearSede={respuestaApiCrearSede}
          textosInterfaz={{
            botones: textosInterfaz.botones,
            descartar_cambios: textosInterfaz.descartar_cambios,
            miga_de_pan: textosInterfaz.miga_de_pan,
            sede_creada: textosInterfaz.sede_creada,
            verificar_datos: textosInterfaz.verificar_datos,
            notificacion: textosInterfaz.notificacion,
          }}
        />
      }
    </>
  )
}

export default VerificarInstituto;

VerificarInstituto.propTypes = {
  /**
   * funcion callback que borra los datos temporales.
   */
   descartarCambios: PropTypes.func.isRequired
}
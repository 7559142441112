import { useQuery } from "@tanstack/react-query";
import { DEFAULT_QUERIES_STALE_TIME } from "../../config/constants";
import { LibrosAPI } from "../../api/LibrosAPI";

export const useBookInfoQuery = (props = {}) => {
	const { enabled = true, bookId=null } = props;
	const query = useQuery(["/libros/datosLibros", bookId], {
		staleTime: DEFAULT_QUERIES_STALE_TIME,
		refetchOnWindowFocus: false,
		queryFn: () =>
			LibrosAPI.buscarPorId({ bookId }).then((res) => res),
		enabled,
	});

	return { ...query };
};

import { useEffect, useState } from "react";
//Store
import { useDispatch, useTrackedState } from "../../store";
import generalCallApi from "./generalCallApi";
//Funciones
import agregarDatosCurso from "./../../utilities/function/agregarDatosCurso";
import transformarDatosEstudiantes from "./../../utilities/function/estudiantes/transformarDatosEstudiantes";
import transformarDatosProfesores from "./../../utilities/function/profesores/transformarDatosProfesores";
import transformarClubs from "./../../utilities/function/clubs/transformarClubs";
import { CancelRequestController } from "../../utilities/cancel-request-controller";
import { useClassesQuery } from "../../hooks/api-queries/use-classes";
import { useTeachersQuery } from "../../hooks/api-queries/use-teachers-query";
import { useStudentsQuery } from "../../hooks/api-queries/use-students-query";
import { useClubsQuery } from "../../hooks/api-queries/use-clubs-query";

/**
 * Dependiendo de la sección que se quiera cargar, se realiza una llamada a la API, se trae la información y se organiza para luego ser desplegada en el store.
 * @param {*} props
 * @returns {{isLoading:boolean}} - Objeto con el estado de carga de la información.
 */
const useInitialDataOfSection = (props) => {
	const { pathname, properties, propiedadJson, property, section } = props;

	const classesQuery = useClassesQuery({enabled: property === 'datosCursos'});
	const teachersQuery = useTeachersQuery({enabled: property === 'datosProfesores'});
	const studentsQuery= useStudentsQuery({enabled: property === 'datosEstudiantes'});
	const clubsQuery= useClubsQuery({enabled: property === 'datosClubs'});

	const state = useTrackedState();
	const { datosInstitucion } = state;
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);


	const loadData = async ({section, data})=>{
		let value;
		switch (section) {
			case "profesores":
				const datosProfesores = await transformarDatosProfesores({
					datos: data,
					sedes: datosInstitucion?.sedes,
				});
				value = {
					resultados: datosProfesores?.resultadosModificados,
					"sin-asignar": datosProfesores?.profesoresSinAsignar,
				};
				break;
			case "cursos":
				value = await agregarDatosCurso({
					datos: data,
					section,
					propiedadJson,
				});
				break;
			case "estudiantes":
				value = await transformarDatosEstudiantes({
					datos: data,
					sedes: datosInstitucion?.sedes,
				});
				break;
			case "clubs":
				value = await transformarClubs({ datos: data });
				break;
			default:
				value = data;
		}

		//Se debe elimiinar cuando la estructura del api se la correcta
		if (pathname === "/sedes/obtenerInstituto") {
			const resultDatosInstituto = data;
			let paquetesSede = [];
			resultDatosInstituto.data.paquetes.length !== 0 &&
				resultDatosInstituto.data.paquetes.map((paquete) =>
					paquetesSede.push({ nombre: paquete })
				);

			let sedes = [];
			resultDatosInstituto.data.sedes.length !== 0 &&
				resultDatosInstituto.data.sedes.map((sede) => {
					return sedes.push({
						...sede,
						numeroTotalDeProfesores: !!sede.numeroTotalDeProfesores
							? sede.numeroTotalDeProfesores
							: 0,
					});
				});

			resultDatosInstituto.data = {
				...resultDatosInstituto.data,
				id: resultDatosInstituto.data.idInstituto,
				pais: "CO",
				paquetes: paquetesSede,
				sedes: sedes,
			};

			value = resultDatosInstituto.data;
		}

		dispatch({
			property: property,
			type: "SET_DATA",
			value: value,
		});

		setIsLoading(false);
	}

	useEffect(()=>{
		if(classesQuery.data && !classesQuery.isLoading && property === 'datosCursos'){
			loadData({section, data: classesQuery.data});
		}
	},[classesQuery.data, classesQuery.isLoading,  propiedadJson, properties, property, pathname, section,datosInstitucion])

	useEffect(()=>{
		if(teachersQuery.data && !teachersQuery.isLoading && property === 'datosProfesores'){
			loadData({section, data: teachersQuery.data});
		}
	},[teachersQuery.data, teachersQuery.isLoading,  propiedadJson, properties, property, pathname, section,datosInstitucion])

	useEffect(()=>{
		if(studentsQuery.data && !studentsQuery.isLoading && property === 'datosEstudiantes'){
			loadData({section, data: studentsQuery.data});
		}
	},[studentsQuery.data, studentsQuery.isLoading,  propiedadJson, properties, property, pathname, section,datosInstitucion])

	useEffect(()=>{
		if(clubsQuery.data && !clubsQuery.isLoading && property === 'datosClubs'){
			loadData({section, data: clubsQuery.data});
		}
	},[clubsQuery.data, clubsQuery.isLoading, propiedadJson, properties, property, pathname, section,datosInstitucion])

	useEffect(() => {
		let abortController = new CancelRequestController();
		let isUnMounted = false;

		const callApi = async () => {
			if (isUnMounted) {
				return;
			}
			if(property === 'datosCursos' || property === 'datosProfesores' || property === 'datosEstudiantes' || property === 'datosClubs') {
				return;
			}
			try {
				let results = await generalCallApi({
					pathname,
					properties,
					config: { signal: abortController.signal },
				});
				loadData({section, data: results.data});
			} catch (error) {
				console.log(error);
			}
		};
		callApi();
		return () => {
			isUnMounted = true;
			abortController.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { isLoading };
};

export default useInitialDataOfSection;

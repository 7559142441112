import { useQuery } from "@tanstack/react-query";
import generalCallApi from "../../containers/helpers/generalCallApi";
import { DEFAULT_QUERIES_STALE_TIME } from "../../config/constants";

/**
 * Hook que utiliza react-query para obtener los cursos,
 * recibe un parametro { building=null } que indica la sede a la que se
 * quiere obtener los cursos, si no se proporciona se obtendran todos los cursos
 * de todas las sedes.
 *
 * Devuelve un objeto con:
 * - isLoading: booleano que indica si esta cargando la informacion
 * - isError: booleano que indica si hubo un error al cargar la informacion
 * - data: objeto que contiene la informacion de los cursos
 * - getClassesASArray: funcion que devuelve los cursos como un array de objetos
 * @param {{ building: string}} props  - objeto que contiene la sede a obtener los cursos
 */
export const useClassesQuery = (props = {}) => {
	const { building, enabled = true } = props;
	const query = useQuery(["/cursos/obtenerCursos", building || ""], {
		staleTime: DEFAULT_QUERIES_STALE_TIME,
		refetchOnWindowFocus: false,
		queryFn: () =>
			generalCallApi({
				pathname: "cursos/obtenerCursos",
				properties: { idSede: building },
			}).then((res) => {
				return res.data;
			}),
		enabled,
	});

	const getClassesASArray = () => {
		let cursos = [];
		const sedes = query?.data;
		if (sedes) {
			Object.values(sedes).forEach((sede) => {
				if (sede?.cursos && Array.isArray(sede?.cursos)) {
					const cursosSede = sede?.cursos.map((curso) => {
						return { ...curso, sedeNombre: sede?.nombre || "N/A" };
					});
					cursos = [...cursos, ...cursosSede];
				}
			});
		}
		return cursos ? cursos : [];
	};

	return { ...query, getClassesASArray };
};

import { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Hooks personalizados
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Funciones
import agregarPropiedadChecked from './../../utilities/function/agregarPropiedadChecked';
import buscarElementos from './../../utilities/function/buscarElementos';
import elegirElementosCheck from '../../utilities/function/elegirElementosCheck';
import generalCallApi from '../helpers/generalCallApi';
import listarArray from './../../utilities/function/listarElementos/listarArray';
import listarObject from '../../utilities/function/listarElementos/listarObject';
import transformarClubs from './../../utilities/function/clubs/transformarClubs';
//Componentes
import VentanaAlerta from '../../components/globales/VentanaAlerta';
import EditarEliminarClubComponent from './../../components/clubs/EditarEliminarClubComponent';
import SkeletonClubEditar from '../../components/clubs/Skeletons/SkeletonClubEditar';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';

/**
 * Este Container recibe las propiedades llamadas isLoadingCursos y isLoadingEstudiantes que permiten renderizar la pantalla de editar y eliminar club. Las funciones principales de este Container son actualizar los datos, buscar y eliminar estudiantes, buscar libros y eliminar club.
 * @returns {JSX.Element} EditarEliminarClubComponent
 */
const EditarEliminarClub = (props) => {
  const { isLoadingCursos, isLoadingEstudiantes } = props

  //Estados globales
  const state = useTrackedState();
  const { datosClubs, datosCursos, datosEstudiantes, datosInstitucion } = state;
  const dispatch = useDispatch();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';
  const queryClient = useQueryClient();


  //Estados del componente    
  //Datos del club
  const [datosClub, setDatosClub] = useState(null);
  const [archivo, setArchivo] = useState(null);
  const [descripcion, setDescripcion] = useState('');
  const [icono, setIcono] = useState([]);
  const [nombre, setNombre] = useState('');
  const isInitialized = useRef(false);


  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [estudiantesElegidos, setEstudiantesElegidos] = useState({
    ids: [],
    datos: []
  })
  const [filtros, setFiltros] = useState({
    sede: { id: null, nombre: '' },
    curso: { id: null, nombre: '' }
  });
  const [instanciaDatosEstudiantes, setInstanciaDatosEstudiantes] = useState(isLoadingEstudiantes);
  const [librosElegidos, setLibrosElegidos] = useState({
    ids: [],
    datos: []
  })
  const [existeClub, setExisteClub] = useState(null);
  const [hayCambios, setHayCambios] = useState({ cambios: false, datos: {} });
  const [hayLimiteLibros, setHayLimiteLibros] = useState(false);
  const [preloader, setPreloader] = useState(null);
  const [respuestaApiEditar, setRespuestaApiEditar] = useState({
    isError: null,
    mensaje: ''
  });
  const [respuestaApiEliminar, setRespuestaApiEliminar] = useState({
    isError: null,
    mensaje: ''
  });
  const [resultadoEstudiantes, setResultadosEstudiantes] = useState({ hayResultados: false, resultados: [] });
  const [resultadoLibros, setResultadosLibros] = useState({ hayResultados: false, resultados: [] });
  const [sedesInstituto, setSedesInstituto] = useState([]);
  const [todosLosEstudiantes, setTodosLosEstudiantes] = useState([]);

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('clubs');


  //Librerias externas
  const history = useHistory();
  const params = useParams();
  const { club } = params;


  useEffect(() => {
    if (!!club && !!datosClubs && !isInitialized.current) {
      isInitialized.current = true;
      //Buscar el club
      const clubActual = datosClubs.find(clubInstituto => clubInstituto.id === club);

      const exiteClubParam = !!clubActual;
      setExisteClub(exiteClubParam);

      if (respuestaApiEliminar.isError !== false) {
        if (exiteClubParam) {
          const infoClub = {
            archivo: clubActual.archivo,
            descripcion: clubActual.descripcion,
            estudiantes: clubActual.estudiantes,
            icono: parseInt(clubActual.icono),
            id: clubActual.id,
            libros: clubActual.libros,
            nombre: clubActual.nombre,
            numeroTotalDeEstudiantes: clubActual.numeroTotalDeEstudiantes,
            numeroTotalDeLibros: clubActual.numeroTotalDeLibros,
          }

          let idsEstudiantes = [];
          infoClub.estudiantes.length !== 0 && infoClub.estudiantes.map(estudiante => idsEstudiantes.push(estudiante.id));

          let idsLibros = [];
          infoClub.libros.length !== 0 && infoClub.libros.map(libro => idsLibros.push(libro.idLibro));


          async function actualizarLibros() {
            await actualizarlibrosInstituto()
          };
          actualizarLibros();

          setDatosClub(infoClub);
          setDescripcion(infoClub.descripcion);
          setIcono(infoClub.icono);
          setNombre(infoClub.nombre);
          setLibrosElegidos({ datos: infoClub.libros, ids: idsLibros })
          setEstudiantesElegidos({ datos: infoClub.estudiantes, ids: idsEstudiantes });
        }
      }
    }
    // history.push('/clubs');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [club, datosClubs, datosEstudiantes, datosCursos])



  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: filtros.sede.id })
      setCursosSede(cursos.resultados)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, filtros.sede.id])


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedesInstituto(listaSedes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!!datosEstudiantes) {
      const datosPropiedadCheck = { ...datosEstudiantes };
      const dataActualizada = inicializarChecked({ data: datosPropiedadCheck })
      resultadosEstudiantes({ data: dataActualizada });
      setInstanciaDatosEstudiantes(dataActualizada);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosEstudiantes])


  //Actualiza el curso a filtrar
  const actualizarCurso = ({ idCurso, idSede, value }) => {
    let nombreSede = datosCursos[idSede].nombre;
    const nombreCurso = datosCursos[idSede].cursos.find(curso => curso.id === idCurso).nombre;

    setFiltros({
      sede: { id: idSede, nombre: nombreSede },
      curso: { id: idCurso, nombre: nombreCurso }
    })

    buscarEstudiantes({ idCurso, idSede, value })
  };


  //Actualiza el descripcion del club
  const actualizarDescripcion = ({ descripcion }) => {
    const propiedades = { descripcion: descripcion !== datosClub.descripcion }
    verificarCambios({ propiedades });
    setDescripcion(descripcion)
  };


  //Actualizar lista de estudiantes seleccionados
  const actualizarEstudiantesElegidos = ({ e, estudiante }) => {
    let estudiantes = [...estudiantesElegidos.datos];
    const resultados = elegirElementosCheck({ e, elemento: estudiante, datosActuales: estudiantes })

    const { ids, datos } = resultados;
    const estudiantesSeleccionados = { ids, datos };

    let instanceDatos = [...resultadoEstudiantes.resultados];

    //Coloca todos los elementos con checked igual a false
    instanceDatos.forEach((elemento, index) => instanceDatos[index] = { ...elemento, checked: false });

    ids.forEach(id => {
      instanceDatos.forEach((instanceEstudiante, index) => {
        if (instanceEstudiante.id === id) {
          instanceDatos[index] = { ...instanceEstudiante, checked: true }
        }
      })
    })


    const propiedades = { estudiantes: true };
    verificarCambios({ propiedades });

    setEstudiantesElegidos(estudiantesSeleccionados);
    setResultadosEstudiantes({ ...resultadoEstudiantes, resultados: instanceDatos })
  }


  //Actualiza el descripcion del club
  const actualizarIcono = ({ icono }) => {
    const propiedades = { icono: icono !== datosClub.icono }
    verificarCambios({ propiedades });
    setIcono(icono)
  };


  //Actualizar lista de libros seleccionados
  const actualizarLibrosElegidos = ({ e, libro }) => {
    let limiteLibros = false;
    let libros = [...librosElegidos.datos];
    if (libros.length < 5 || !e.target.checked) {
      const propiedades = { libros: true };
      verificarCambios({ propiedades });

      const resultados = elegirElementosCheck({ e, elemento: libro, datosActuales: libros, id: 'idLibro' });


      const { ids, datos } = resultados;
      const librosSeleccionados = { ids, datos };

      let instanceDatos = [...resultadoLibros.resultados];

      //Coloca todos los elementos con checked igual a false
      instanceDatos.forEach((elemento, index) => instanceDatos[index] = { ...elemento, checked: false });

      ids.forEach(id => {
        instanceDatos.forEach((instanceLibros, index) => {
          if (instanceLibros.idLibro === id) {
            instanceDatos[index] = { ...instanceLibros, checked: true }
          }
        })
      })

      setLibrosElegidos(librosSeleccionados);
      setResultadosLibros({ ...resultadoEstudiantes, resultados: instanceDatos })
    } else {
      limiteLibros = true;
    }

    setHayLimiteLibros(limiteLibros)
  }


  //Actualiza el nombre del club
  const actualizarNombre = ({ nombre }) => {
    const propiedades = { nombre: nombre !== datosClub.nombre }
    verificarCambios({ propiedades });
    setNombre(nombre)
  };


  //Actualiza el nombre del club
  const actualizarlibrosInstituto = async () => {
    const respuesta = await generalCallApi({
      pathname: '/libros/datosLibros',
      properties: { idSede: null }
    });
    const error = respuesta.status === 0;

    if (!error) {
      buscarLibros({ value: '', datos: respuesta.data })
    }
  };


  //Actualiza la sede asignada
  const actualizarSede = ({ idSede, value }) => {
    if (idSede !== filtros.sede.id) {
      const nombreSede = datosCursos[idSede].nombre;

      setFiltros({
        sede: { id: idSede, nombre: nombreSede },
        curso: { id: null, nombre: '' }
      });

      buscarEstudiantes({ idSede, value })
    };
  };


  //Buscar estudiantes
  const borrarEstudiante = ({ idEstudiante }) => {
    let estudiantes = [...estudiantesElegidos.datos];
    let idEstudiantes = [...estudiantesElegidos.ids];

    const posicion = estudiantes.findIndex(estudiante => estudiante.id === idEstudiante);
    estudiantes.splice(posicion, 1);
    idEstudiantes.splice(posicion, 1);

    setEstudiantesElegidos({
      datos: estudiantes,
      ids: idEstudiantes
    });


    const propiedades = { estudiantes: true };
    verificarCambios({ propiedades });
  }


  //Buscar estudiantes
  const buscarEstudiantes = ({ cursoObligatorio, idCurso, idSede, value }) => {
    if (!!instanciaDatosEstudiantes) {
      const sede = !!idSede ? idSede : filtros.sede.id;
      const curso = cursoObligatorio === true ? idCurso : !!idCurso ? idCurso : filtros.curso.id;
      const datos = !!sede ? instanciaDatosEstudiantes : todosLosEstudiantes;

      let resultados = buscarElementos({ isCursos: true, sede, curso, value, datos, propiedad: 'estudiantes' })

      estudiantesElegidos.ids.map(id => {
        const indexEstudiante = resultados.resultados.findIndex(estudiante => estudiante.id === id);
        if (indexEstudiante !== -1) {
          resultados.resultados[indexEstudiante] = {
            ...resultados.resultados[indexEstudiante],
            checked: true
          }
        }
        return id;
      })


      const resultadoBusqueda = {
        hayResultados: resultados.hayResultados,
        resultados: resultados.resultados
      }

      setResultadosEstudiantes(resultadoBusqueda);
    }
  }


  //Buscar estudiantes
  const buscarLibros = ({ value, datos }) => {
    const libros = !!datos ? datos : resultadoLibros.resultados;
    let resultados = buscarElementos({ value, datos: libros });

    const resultadoBusqueda = {
      hayResultados: resultados.hayResultados,
      resultados: resultados.resultados
    }

    setResultadosLibros(resultadoBusqueda)
  }


  //Eliminar club
  const editarClub = async () => {
    if (!preloader) {
      setRespuestaApiEditar({
        isError: null,
        mensaje: ''
      });

      setPreloader(true);

      //Parámetros parap el llamado del api
      const pathname = "/clubs/editarClub";
      const properties = {
        archivo: !!archivo ? archivo.archivo : null,
        descripcion: descripcion,
        icono: icono,
        idClub: club,
        idEstudiantes: estudiantesElegidos.ids,
        idLibros: librosElegidos.ids,
        nombre: nombre
      };

      
      let formData = new FormData();
      for (const key in properties) {
        let value = properties[key];
        if (key !== 'descripcion' && key !== 'nombre'&& key !== 'idClub' && key !== 'icono' && key !== 'archivo') {
          value = JSON.stringify(value)
        }
        formData.append(key, value);
      }

      //Llamar api de eliminar club
      const result = await generalCallApi({ pathname, properties: formData, formdata: true });
      const error = result.status === 0;


      if (!error) {
        queryClient.invalidateQueries({ queryKey: ["/clubs/obtenerClubs"] });
      };

      setRespuestaApiEditar({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    }
  }


  //Eliminar club
  const eliminarClub = async () => {
    if (!preloader && respuestaApiEliminar.isError !== false) {
      setRespuestaApiEliminar({
        isError: null,
        mensaje: ''
      });

      setPreloader(true);

      //Parámetros parap el llamado del api
      const pathname = "/clubs/borrarClub";
      const properties = { idClub: club };


      //Llamar api de eliminar club
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        queryClient.invalidateQueries({ queryKey: ["/clubs/obtenerClubs"] });
      };

      setRespuestaApiEliminar({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    }
  };


  //Inicializar la propiedad checked de los elementos
  const inicializarChecked = ({ data }) => {
    const newData = { ...data }
    for (const key in data) {
      let sede = newData[key];
      for (const keySede in sede.cursos) {
        let cursos = sede.cursos[keySede];
        const nuevoDatoEstudiante = agregarPropiedadChecked({ data: cursos.estudiantes });

        newData[key] = {
          ...newData[key], cursos: {
            ...newData[key].cursos,
            [keySede]: { ...cursos, estudiantes: nuevoDatoEstudiante }
          }
        }
      }
    }

    return newData
  }


  //Listar cursos de la sede
  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }


  //Almacena los datos del input file
  const obtenerDatosInput = ({ archivo, e, nombre }) => {
    setArchivo({ archivo, e, nombre });
  }


  //Verificar cambios
  const verificarCambios = ({ propiedades }) => {
    const datos = { ...hayCambios.datos, ...propiedades };
    let cambios;

    for (const key in datos) {
      const element = datos[key];
      if (element) cambios = true;
    }


    setHayCambios({
      hayCambios: cambios,
      datos: { ...hayCambios.datos, ...propiedades }
    });
  }


  //Listar resultados
  const resultadosEstudiantes = ({ data }) => {
    const datos = listarObject({ object: data, idSede: filtros.sede.id, idCurso: filtros.curso.id, isCursos: true, propiedad: 'estudiantes' });
    const { cursoBuscar, errorCurso, errorSede, resultados, sedeBuscar, todosLosElementos } = datos;


    const datosSede = { id: sedeBuscar, nombre: !errorSede && !!sedeBuscar ? instanciaDatosEstudiantes[sedeBuscar].nombre : '' }
    const datosCurso = { id: cursoBuscar, nombre: !errorSede && !!sedeBuscar && !!cursoBuscar && !errorCurso ? instanciaDatosEstudiantes[sedeBuscar].cursos[cursoBuscar].nombre : '' }

    const usuario = state?.datosDeUsuario;
    if(rolUsuario==='profesor'){
      const estudiantes = resultados.resultados || [];
      const estudiantesDelProfe = estudiantes?.reduce((acc,curr)=>{
        const profes = curr?.nombreProfesor?.split(',');
        if(profes.includes(usuario?.nombre)){
          acc.push(curr);
        }
        return acc;
      },[])

      setResultadosEstudiantes({hayResultados:estudiantesDelProfe?.length > 0, resultados:estudiantesDelProfe});
    }else{
      setResultadosEstudiantes(resultados);

    }

    setFiltros({ ...filtros, sede: datosSede, curso: datosCurso });
    setTodosLosEstudiantes(todosLosElementos);
  }


  //Componente que se va a renderizar 
  const componenteRender = () => {
    if (isReady && !isLoadingCursos && !isLoadingEstudiantes && !!datosClub) {
      return (
        <>
          <EditarEliminarClubComponent
            actualizarCurso={actualizarCurso}
            actualizarDescripcion={actualizarDescripcion}
            actualizarEstudiantesElegidos={actualizarEstudiantesElegidos}
            actualizarLibrosElegidos={actualizarLibrosElegidos}
            actualizarNombre={actualizarNombre}
            actualizarIcono={actualizarIcono}
            actualizarSede={actualizarSede}
            borrarEstudiante={borrarEstudiante}
            buscarEstudiantes={buscarEstudiantes}
            cursosSede={cursosSede}
            datosClub={datosClub}
            editarClub={editarClub}
            eliminarClub={eliminarClub}
            estudiantesElegidos={estudiantesElegidos}
            existeClub={existeClub}
            filtros={filtros}
            hayCambios={hayCambios.hayCambios}
            hayLimiteLibros={hayLimiteLibros}
            iconoClub={icono}
            librosElegidos={librosElegidos}
            numeroLibrosElegidos={librosElegidos.datos.length}
            obtenerDatosInput={obtenerDatosInput}
            preloader={preloader}
            respuestaApiEditarClub={respuestaApiEditar}
            respuestaApiEliminarClub={respuestaApiEliminar}
            resultadoEstudiantes={resultadoEstudiantes}
            resultadoLibros={resultadoLibros}
            sedesInstituto={sedesInstituto}
            textosInterfaz={textosInterfaz}
          />
        </>
      );
    }

    if (isReady && existeClub === false && respuestaApiEliminar.isError !== false) {
      return (
        <VentanaAlerta
          imagen={{
            alt: 'blop con un icono de alerta',
            img: 'alertas/blop-alerta.png',
          }}
          link='/clubs'
          textosInterfaz={{
            descripcion: textosInterfaz.no_existe_curso.descripcion,
            textoBoton: textosInterfaz.botones.volver,
            titulo: textosInterfaz.no_existe_curso.titulo,
          }}
        >
          <Link
            className="boton-justo mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado"
            to='/clubs'
          >
            {textosInterfaz.botones.volver}
          </Link>
        </VentanaAlerta>
      )
    }
    return <SkeletonClubEditar />;
  };


  return (
    <>
      {componenteRender()}
    </>
  );
};


export default EditarEliminarClub;

EditarEliminarClub.propTypes = {
  /**
    * Es un booleano que indica si la información del curso ya cargo. 
    */
  isLoadingCursos: PropTypes.bool.isRequired,
  /**
  * Es un booleano que indica si la información de los estudiantes ya cargaron. 
  */
  isLoadingEstudiantes: PropTypes.bool
}
import { useQuery } from "@tanstack/react-query";
import generalCallApi from "../../containers/helpers/generalCallApi";
import { DEFAULT_QUERIES_STALE_TIME } from "../../config/constants";

export const useClubsQuery = (props = {}) => {
	const { enabled = true } = props;
	const query = useQuery(["/clubs/obtenerClubs"], {
		staleTime: DEFAULT_QUERIES_STALE_TIME,
		refetchOnWindowFocus: false,
		queryFn: () =>
			generalCallApi({
				pathname: "/clubs/obtenerClubs",
			}).then((res) => {
				return res.data;
			}),
		enabled,
	});

	return { ...query };
};

import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
//Store
import { useDispatch } from "../store";
//Funciones
import generalCallApi from "../containers/helpers/generalCallApi";
import { useQuery } from "@tanstack/react-query";
import { useInstitutionQuery } from "./api-queries/use-institution-query";

const useMainDataLoader = () => {
	// React Query: Login
	const loginQuery = useQuery({
		queryKey: ["/login"],
		queryFn: () => generalCallApi({ pathname: "/login" }),
		staleTime: 60 * 1000,
		refetchOnWindowFocus: false,
	});

	// React Query: Paquetes Disponibles
	const availablePackagesQuery = useQuery({
		queryKey: ["/paquetes/paquetesDisponibles"],
		queryFn: () => generalCallApi({ pathname: "/paquetes/paquetesDisponibles" }),
		staleTime: 60 * 1000,
		refetchOnWindowFocus: false,
	});

	// React Query: Datos del Instituto
	const institutionQuery = useInstitutionQuery();

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const removeSplashScreen = () => {
		dispatch({
			type: "SET_DATA",
			property: "isPending",
			value: false,
		});
	};

	const dataLoader = async () => {
		const response = loginQuery?.data;
		const error = response.status === 0;
		if (!error) {
			const data = response.data;
			const instituto = data.instituto;
			switch (data.rol) {
				case "rector":
					if (location.pathname === "/" && Boolean(data.eligiendoLibros) === false) {
						history.push("/institutos");
					} else if (location.pathname === "/" && Boolean(data.eligiendoLibros))
						history.push("/libros");
					break;
				case "profesor":
					if (location.pathname === "/") history.push("/cursos");
					break;
				case "estudiante":
					if (location.pathname === "/") history.push("/estudiante/home");
					break;
				default:
					history.push("/404");
					break;
			}
			if (data.rol !== "estudiante") {
				//Datos de usuario
				const contrasena =
					Math.random().toString(36).slice(2) +
					Math.random().toString(36).toUpperCase().slice(2);

				const avatarRuta = data.avatar.replace("/img/", "/images/avatares/");
				const datosUsurio = {
					avatar: avatarRuta,
					contrasena: contrasena,
					correo: data.email,
					instituto: instituto.nombre,
					nombre: data.nombreCompleto,
				};

				dispatch({
					type: "SET_DATA",
					property: "datosDeUsuario",
					value: datosUsurio,
				});

				//Informacion plan activo
				const planActivo = {
					eligiendoLibros: data.eligiendoLibros === 1 ? true : false,
					// eligiendoLibros: false,
					fechaFinSuscripcion: data.fechaFinSuscripcion,
					numeroMaximoDeSedes: instituto.numeroMaximoDeSedes,
					crearCursos: data.crearCursos === 1 ? true : false,
					crearClubs: data.crearClubs === 1 ? true : false,
					crearEstudiantes: data.crearEstudiantes === 1 ? true : false,
					crearProfesores: data.crearProfesores === 1 ? true : false,
					crearSedes: data.crearSedes === 1 ? true : false,
					escogerLibros: data.escogerLibros === 1 ? true : false,
					tipoUsuario: data.tipoUsuario,
					verClubs: data.verClubs === 1 ? true : false,
					verEstadisticas: data.verEstadisticas === 1 ? true : false,
					verEstudiantes: data.verEstudiantes === 1 ? true : false,
					verProfesores: data.verProfesores === 1 ? true : false,
					verSedes: data.verSedes === 1 ? true : false,
					color: { ...instituto.color },
				};

				dispatch({
					type: "SET_DATA",
					property: "planActivo",
					value: planActivo,
				});

				const resultPaquete = availablePackagesQuery.data;

				const paquetes = [];

				resultPaquete.data.length !== 0 &&
					resultPaquete.data.map((paquete) => paquetes.push(paquete));

				dispatch({
					type: "SET_DATA",
					property: "paquetes",
					value: paquetes,
				});
			} else {
				const avatarRuta = data.avatar.replace("/img/", "/images/avatares/");
				const infoEstudiante = {
					...data,
					avatar: avatarRuta,
					codigo: data.codigo,
					correo: data.correo,
					id: data.idEstudiantePlanLector,
					nombre: data.nombre,
					codigoColegio: data.codigoColegio,
					idCursoPlanLector: data.idCursoPlanLector,
					totalHorasLeidas: data?.totalHorasLeidas,
					totalLibrosLeidos: data?.totalLibrosLeidos,
					totalLogros: data?.totalLogros,
				};

				dispatch({
					type: "SET_DATA",
					property: "planActivo",
					value: { tipoUsuario: "estudiante" },
				});

				dispatch({
					type: "SET_DATA",
					property: "perfilEstudiante",
					value: infoEstudiante,
				});
			}
		} else {
			const currentPath = location.pathname;
			if (
				!currentPath?.includes("/login/recuperar-contrasena") ||
				!currentPath?.includes("/login")
			) {
				history.push("/login");
			}
		}
	};

	useEffect(() => {
		if (
			loginQuery.isSuccess &&
			availablePackagesQuery.isSuccess &&
			institutionQuery.isSuccess
		) {
			removeSplashScreen();
			dataLoader();
		}
	}, [loginQuery.status, availablePackagesQuery.status, institutionQuery.status]);

	useEffect(() => {
		if (
			!loginQuery.isLoading &&
			!availablePackagesQuery.isLoading &&
			!institutionQuery.isLoading
		) {
			removeSplashScreen();
		}
	}, [loginQuery.isLoading, availablePackagesQuery.isLoading, institutionQuery.isLoading]);
};

export default useMainDataLoader;

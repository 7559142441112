import { useQuery } from "@tanstack/react-query";
import generalCallApi from "../../containers/helpers/generalCallApi";
import { useDispatch } from "../../store";
import { DEFAULT_QUERIES_STALE_TIME } from "../../config/constants";

export const useInstitutionQuery = () => {
	const dispatch = useDispatch();
	const query = useQuery(["/sedes/obtenerInstituto"], {
		staleTime: DEFAULT_QUERIES_STALE_TIME,
		refetchOnWindowFocus: false,
		queryFn: () =>
			generalCallApi({ pathname: "/sedes/obtenerInstituto" })
				.then((res) => {
					if (res.status === 0) {
						return { x: 2 };
					}
					let resultDatosInstituto = res.data;

					let paquetesSede = [];
					resultDatosInstituto.paquetes.length !== 0 &&
						resultDatosInstituto.paquetes.map((paquete) =>
							paquetesSede.push({ nombre: paquete })
						);

					let sedes = [];
					resultDatosInstituto.sedes.length !== 0 &&
						resultDatosInstituto.sedes.map((sede) => {
							return sedes.push({
								color: sede.color,
								id: sede.id,
								correo: sede.correo,
								cursos: sede.cursos,
								fechaFinSuscripcion: sede.fechaFinSuscripcion,
								fechaInicioSuscripcion: sede.fechaInicioSuscripcion,
								nombre: sede.nombre,
								numeroDeLibrosAsignados: sede.numeroDeLibrosAsignados,
								numeroTotalDeEstudiantes: sede.numeroTotalDeEstudiantes,
								numeroTotalDeProfesores: !!sede.numeroTotalDeProfesores
									? sede.numeroTotalDeProfesores
									: 0,
								paquete: sede.paquetes,
								telefono: sede.telefono,
							});
						});

					resultDatosInstituto = {
						...resultDatosInstituto,
						id: resultDatosInstituto.idInstituto,
						pais: "CO",
						paquetes: paquetesSede,
						sedes: sedes,
					};
					dispatch({
						type: "SET_DATA",
						property: "datosInstitucion",
						value: resultDatosInstituto,
					});
					return res.data;
				})
				.catch((err) => console.log(err)),
	});

	const getBuildings = () => {
		const buildings = Array.isArray(query?.data?.sedes) ? query?.data?.sedes : [];
		return buildings;
	};

	return { ...query, getBuildings };
};

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
//Hooks y funciones personalizadas
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Store
import { useDispatch } from '../../store';
//Componentes
import LoginComponent from "../../components/login";
import generalCallApi from './../helpers/generalCallApi';
import { LocalStorage } from '../../services/local-storage/LocalStorage';
import { ModalSuscripcionCaducada } from '../../components/globales/modales/ModalSuscripcionCaducada';
import { normalizeString } from '../../utilities/StringUtilities';
import { useInstitutionQuery } from '../../hooks/api-queries/use-institution-query';

/**
 * Container que no recibe props. Este container retorna el componente LoginComponent al cual se le pasa una serie de propiedades para renderizar la pantalla de login. Las funciones principales de este container son ocultar o mostrar el mensaje de error e iniciar sesiones dependiendo del tipo de usuario. 
 * @returns {JSX.Element} LoginComponent
 */
const Login = () => {
  //Estados globales
  const dispatch = useDispatch();

  //Estados del componente
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({
    hayError: false,
    tipoError: null
  });
  const [mensaje, setMensaje] = useState('');

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('login');
  const history = useHistory();
  const [modalSuscripcionCaducadaVisible, setModalSuscripcionCaducadaVisible] = useState(false);
  
  /**
   * Define que tipo de cliente el usuario está intentando acceder.
   */
  const [userType, setUserType] = useState('');
  const intitutionQuery = useInstitutionQuery();
  const institutionData = intitutionQuery?.data || {};
  console.log('test',institutionData)



  const mostrarOcultarMensajeError = ({ value, message, tipoError }) => {
    const nuevoMensaje = message !== undefined ? message : '';
    setIsError({
      hayError: value,
      tipoError: !value ? null : tipoError
    });
    setMensaje(nuevoMensaje);
  }

  /**
   * función que se encarga de llamar el API de login y actualizar los datos del estudiante en el storage
   * @param {Event} e
   * @param {string} codigo
   */
  const fn_iniciarSesionEstudiante = async ({ e, codigo }) => {
    setUserType('estudiante');
    e.preventDefault();

    if (codigo !== '') {
      setIsLoading(true);

      const pathname = '/login';
      const properties = {
        codigo: codigo,
        rol: 'estudiante'
      };

      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;
      if (!error) {
        LocalStorage.guardarEstadoSesion(LocalStorage.SESION_ESTADOS.activa)
        dispatch({
          type: "SET_DATA_SESION",
          property: "sesionActual",
          value: {estado:LocalStorage.obtenerEstadoSesion()}
        })
        mostrarOcultarMensajeError({ value: false });
        const data = result.data;

        const avatarRuta = data.avatar.replace("/img/", "/images/avatares/");

        const infoEstudiante = {
          ...data,
          avatar: avatarRuta,
          codigo: data.codigo,
          correo: data.correo,
          id: data.idEstudiantePlanLector,
          nombre: data.nombre,
        }

        dispatch({
          type: "SET_DATA",
          property: "planActivo",
          value: { tipoUsuario: 'estudiante' }
        })

        dispatch({
          type: "SET_DATA",
          property: "perfilEstudiante",
          value: infoEstudiante
        })

        dispatch({
          type: "SET_DATA",
          property: "datosInstitucion",
          value: data.instituto
        })


        history.push('/estudiante/home');
      }else {
        LocalStorage.guardarEstadoSesion(LocalStorage.SESION_ESTADOS.noAutenticado)
        dispatch({
          type: "SET_DATA_SESION",
          property: "sesionActual",
          value: {estado:LocalStorage.obtenerEstadoSesion()}
        })
        setIsLoading(false);
        const tipoError =  result.info.search('El estudiante no existe o fue desactivado de la plataforma') !== -1 && 'estudiante_incorrecto';
        const suscripcionCaducada = normalizeString(result?.info || '').includes('la suscripcion ha caducado');
        if(suscripcionCaducada){
          setModalSuscripcionCaducadaVisible(true);
          setIsError({hayError:true, tipoError:'suscripcion-caducada'})
        }else{
          mostrarOcultarMensajeError({ value: true, mensaje: error, tipoError });
        }
      }
     
    }
  }

  /**
   *  función que se encarga de llamar el API de login.
   * @param {event} e 
   * @param {string} correo 
   * @param {string} contrasena 
   */
  const fn_iniciarSesion = async ({ e, correo, contrasena }) => {
    e.preventDefault();
    setUserType('profesor');
    if (correo !== '' && contrasena !== '') {
      setIsLoading(true);
      const pathname = '/login';
      const properties = {
        correo: correo,
        contrasena: contrasena
      };
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;


      if (!error) {
        mostrarOcultarMensajeError({ value: false });
        const data = result.data;
        const instituto = data.instituto;
        LocalStorage.guardarEstadoSesion(LocalStorage.SESION_ESTADOS.activa)
        dispatch({
          type: "SET_DATA_SESION",
          property: "sesionActual",
          value: {estado:LocalStorage.obtenerEstadoSesion()}
        })
        //Datos de usuario
        const contrasena = Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2);

        const avatarRuta = data.avatar.replace("/img/", "/images/avatares/");
        
        const datosUsurio = {
          avatar: avatarRuta,
          contrasena: contrasena,
          correo: data.email,
          instituto: instituto.nombre,
          nombre: data.nombreCompleto,
        }

        dispatch({
          type: "SET_DATA",
          property: "datosDeUsuario",
          value: datosUsurio
        })


        //Informacion plan activo
        const planActivo = {
          eligiendoLibros: data.eligiendoLibros === 1 ? true : false,
          // eligiendoLibros: false,
          fechaFinSuscripcion: data.fechaFinSuscripcion,
          numeroMaximoDeSedes: instituto.numeroMaximoDeSedes,
          crearCursos: data.crearCursos === 1 ? true : false,
          crearClubs: data.crearClubs === 1 ? true : false,
          crearEstudiantes: data.crearEstudiantes === 1 ? true : false,
          crearProfesores: data.crearProfesores === 1 ? true : false,
          crearSedes: data.crearSedes === 1 ? true : false,
          escogerLibros: data.escogerLibros === 1 ? true : false,
          tipoUsuario: data.tipoUsuario,
          verClubs: data.verClubs === 1 ? true : false,
          verEstadisticas: data.verEstadisticas === 1 ? true : false,
          verEstudiantes: data.verEstudiantes === 1 ? true : false,
          verProfesores: data.verProfesores === 1 ? true : false,
          verSedes: data.verSedes === 1 ? true : false,
          color: { ...instituto.color },
        }

        dispatch({
          type: "SET_DATA",
          property: "planActivo",
          value: planActivo
        })


        const resultPaquete = await generalCallApi({
          pathname: '/paquetes/paquetesDisponibles'
        });

        const paquetes = [];
        resultPaquete.data.length !== 0 && resultPaquete.data.map(paquete => paquetes.push({ nombre: paquete.nombre }))

        dispatch({
          type: "SET_DATA",
          property: "paquetes",
          value: paquetes
        })
        window.location.href = '/';

      }
      else {
        LocalStorage.guardarEstadoSesion(LocalStorage.SESION_ESTADOS.noAutenticado)
        dispatch({
          type: "SET_DATA_SESION",
          property: "sesionActual",
          value:{estado:LocalStorage.obtenerEstadoSesion()}
        })
        setIsLoading(false);
        const tipoError = result.info.search('Datos incorrectos') !== -1
          ? 'datos_icorrectos'
          : 'usuario_icorrecto';
        const suscripcionCaducada = normalizeString(result?.info || '').includes('la suscripcion ha caducado');
        if(suscripcionCaducada){
          setModalSuscripcionCaducadaVisible(true);
          setIsError({hayError:true, tipoError:'suscripcion-caducada'})
        }else{
          mostrarOcultarMensajeError({ value: true, mensaje: error, tipoError });
        }
      }
    }else{
      mostrarOcultarMensajeError({ value: true, mensaje: '', tipoError:'campos_vacios' });
    }
  }

  return (
    <>
      {
        isReady &&
        <>
          <ModalSuscripcionCaducada
            visible={modalSuscripcionCaducadaVisible}
            setVisible={setModalSuscripcionCaducadaVisible}
            userType={userType}
          />
          <LoginComponent
            fn_iniciarSesionEstudiante={fn_iniciarSesionEstudiante}
            isError={isError}
            isLoading={isLoading}
            fn_iniciarSesion={fn_iniciarSesion}
            mensaje={mensaje}
            mostrarOcultarMensajeError={mostrarOcultarMensajeError}
            textosInterfaz={textosInterfaz}
          />
        </>
      }
    </>
  )
}

export default Login;
import {  useMemo } from "react";
import { useClassesQuery } from "../../../hooks/api-queries/use-classes";

export const useClasses = ()=>{
    const classesQuery = useClassesQuery({building:null});
    const classes = useMemo(()=>{
        return classesQuery.getClassesASArray() || [];
    },[classesQuery?.data, classesQuery?.isLoading]);
    const loading = classesQuery?.isLoading || classesQuery?.isFetching;	    

    const getBuildings = ({classes})=>{
        if(!Array.isArray(classes)){return []};
        const _buildings = classes.map(curso => {
            return {
                idSede: curso.idSede,
                sedeNombre: curso.sedeNombre
            };
        });

        const filtered = _buildings.filter((sede, index, self) => 
            index === self.findIndex((s) => (
                s.idSede === sede.idSede && s.sedeNombre === sede.sedeNombre
            ))
        );
    
        return filtered;
    }

    const buildings = useMemo(()=>{
        if(loading){return []}
        return getBuildings({classes});
    },[classes, loading]);


 
    return {
        loading,
        classes,
        buildings
    }
}
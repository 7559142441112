import OpcionesHeaderComponent from './../../components/header/OpcionesHeader';
//Hooks y funciones personalizadas
import useObtenerTraducciones from '../helpers/hooks/useObtenerTraducciones';
//Store
import { useDispatch, useTrackedState } from '../../store';
import { useHistory } from 'react-router-dom';
import { AutenticacionAPI } from '../../api/AutenticacionAPI';
import { LocalStorage } from '../../services/local-storage/LocalStorage';

/**
 * Este componente es de tipo container y no recibe props. Se encarga de crear la logica para cerrar sesión y cambiar de idioma. También, renderiza  el componente OpcionesHeaderComponent al cual se le pasa una serie de propiedades que le permiten mostrarlas opciones del header que también aparecen en responsive en el menú. 
 * @returns {JSX.Element} OpcionesHeaderComponent
 */
const OpcionesHeader = () => {
  //Estados globales
  const state = useTrackedState();
  const { idioma } = state;
  const dispatch = useDispatch();

  //Hooks personalizados
  const { isReady, textosSeccion: textosInterfaz } = useObtenerTraducciones('header');
  const history = useHistory()

  //Cerrar sesión
  const cerrarSesion = async () => {
    await AutenticacionAPI.logout()
    .then(resultado=>{
      LocalStorage.guardarEstadoSesion(LocalStorage.SESION_ESTADOS.finalizadaPorUsuario)
      window.location.href = '/login';
    })
    .catch(error=>console.log(error))
  }

  /**
   * Cambiar idioma
   * @param {string} idioma 
   */
  const cambiarIdioma = (idioma) => {
    dispatch({
      type: "SET_DATA",
      property: "idioma",
      value: idioma
    })
  }

  return (
    <>
      {isReady &&
        <OpcionesHeaderComponent
          cambiarIdioma={cambiarIdioma}
          cerrarSesion={cerrarSesion}
          idiomaActivo={idioma}
          idiomasDisponibles={process.env.REACT_APP_IDIOMAS_DISPONIBLES.split(',')}
          textosInterfaz={textosInterfaz}
        />
      }
    </>
  )
}

export default OpcionesHeader;
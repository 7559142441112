import { useQuery } from "@tanstack/react-query";
import generalCallApi from "../../containers/helpers/generalCallApi";
import { DEFAULT_QUERIES_STALE_TIME } from "../../config/constants";

export const useStudentsQuery = (props = {}) => {
	const { enabled = true } = props;
	const query = useQuery(["/estudiantes/obtenerListadoEstudiantes"], {
		staleTime: DEFAULT_QUERIES_STALE_TIME,
		refetchOnWindowFocus: false,
		queryFn: () =>
			generalCallApi({
				pathname: "/estudiantes/obtenerListadoEstudiantes",
			}).then((res) => {
				return res.data;
			}),
		enabled,
	});

	const getAllasArray = () => {
		const data = query?.data;
		if (Array.isArray(data) && data.length <= 0) {
			return [];
		}
		const cursosTotales = [];

		Object.values(data || {}).forEach((sede, i) => {
			const cursos = sede?.cursos;
			Object.values(cursos).forEach((curso) => {
				cursosTotales.push({
					...curso,
					sede: {
						id: Object.keys(data)[i],
						nombre: sede?.nombre,
					},
				});
			});
		});

		let estudiantes = [];
		cursosTotales.forEach((curso) => {
			const est = curso?.estudiantes;
			Object.values(est).forEach((estudiante) => {
				estudiantes.push({
					...estudiante,
					nombreCurso: curso?.nombre,
				});
			});
		});
		return estudiantes;
	};

	const getByClassId = ({ classId }) => {
		const classData = Object.values(query?.data || {})?.find((sede) => sede?.cursos?.[classId])
			?.cursos?.[classId];
		const students = Object.values(classData?.estudiantes || {}).map((estudiante) => ({
			...estudiante,
			nombreCurso: classData?.nombre || "N/A",
		}));
		if (Array.isArray(students)) {
			return students;
		}
		return [];
	};

	return { ...query, getAllasArray, getByClassId };
};

import { useState, useRef, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";

/**container */
import FiltrosContainer from "../../containers/libros/FiltrosContainer";
import useIsMobile from "../../containers/helpers/hooks/useIsMobile";

/**componentes */
import MultiselectBar from "./MultiselectBar/MultiselectBar";
import SedesSelection from "./SedesSelection";
import ListadoLibros from "./ListadoLibros";
import { Miga, MigasDePan } from "../globales/MigaDePan";
import PropTypes from "prop-types";
import { AccesibilidadContext, TemasDisponibles } from "../../contexts/AccesibilidadContext";

/**
 * Este componente se encarga de reenderizar todos los libros dependiendo de la sede y/o curso seleccionado, también actualiza el estado de los libros (si están ocultos, agregados a favoritos, filtrados,  etc)
 */
const SuscripcionActivaComponent = (props) => {
	const {
		selectFunctions,
		setLibrosFiltrados,
		librosFiltrados,
		setSchFilter,
		schFilter,
		compileCursosData,
		AnalisisColeccion,
		textosInterfaz,
		cursosData,
		itemsActivos,
		librosData,
		setModoFiltro,
	} = props;

	const { miga_de_pan, libros, botones } = textosInterfaz;
	const { select, sesion_activa } = libros;

	const [activadorSelect, setActivadorSelect] = useState(false);
	const inicioListaRef = useRef(null);
	const [cursoActual, setCursoActual] = useState(null);
	const [librosVisibles, setLibrosVisibles] = useState([]);
	const acc = useContext(AccesibilidadContext);

	//Hooks personalizados
	const isMobile = useIsMobile();

	/**
	 * Esta función se usa para volver  al inicio de la lista de libros
	 */
	const retornarAlPrincipio = () => {
		//  inicioListaRef?.current?.scrollIntoView({block:'start'})
		const top = inicioListaRef.current.offsetTop - 150;
		window.scrollTo({ top: top });
	};

	useEffect(() => {
		const cursos = cursosData[itemsActivos?.sede]?.cursos || [];
		const curso = cursos.find((curso) => curso?.id === itemsActivos?.curso);
		setCursoActual(curso);
		selectFunctions?.deactivateMultiselect();
	}, [itemsActivos, cursosData]);

	useEffect(() => {
		const libros = Array.isArray(librosData?.libros)?librosData?.libros:[];
		const visibles = libros.filter(libro=>libro?.cursos?.includes(cursoActual?.id))
		setLibrosVisibles(visibles);
	}, [librosData, cursoActual]);

	return (
		<>
			<MultiselectBar {...props} />

			<MigasDePan>
				<Miga texto={miga_de_pan.libros || "Libros"} esRutaActual={true} />
			</MigasDePan>

			<div className="contenido">
				<div className="border-b-2 onsurface-var1--borderb-color xl:flex justify-between items-end relative">
					<h1 className="q7-28 onbackground-var1--color">{sesion_activa.titulo}</h1>
				</div>
				<div className="mt-3 mb-5">
					<p className="informacion-principal sm:inline">
						{sesion_activa.descripcion}
						<span
							className="ml-1 w-7 h-7 text-24 surface-var2--bg onsurface-var2--color cursor-pointer hover:bg-morado center inline-flex  rounded-full "
							data-tip={true}
							data-scroll-hide={true}
							data-for="tool-descripcion"
						>
							?
						</span>
					</p>
					<ReactTooltip
						backgroundColor={
							acc.temaActual === TemasDisponibles.clasico?.id
								? "#000"
								: "var(--background-var1)"
						}
						textColor={
							acc.temaActual === TemasDisponibles.clasico?.id
								? "#FFF"
								: "var(--onbackground-var1)"
						}
						className="quicksand-400"
						id="tool-descripcion"
						place={isMobile ? "bottom" : "right"}
						event="mouseover mouseenter click"
						eventOff="mouseleave mouseout scroll mousewheel blur"
						effect={isMobile ? "solid" : "float"}
					>
						<p className="w-40 sm:w-72">{sesion_activa.tooltip}</p>
					</ReactTooltip>
				</div>

				<SedesSelection
					{...props}
					cursosData={compileCursosData()}
					textosInterfaz={select}
				/>

				{/* AE: Fue necesario crear un container para los filtros para evitar problemas de performance si ambos compartian los mismos estados */}
				<div className="lg:flex  border-t onsurface-var1--bordert-color mt-5 py-5">
					<div className="columna-filtro z-60">
						{/* BOTON RESPONSIVE */}
						<button
							className="focusable-red w-full text-22 center py-2 boton-azul boton_hover_morado lg:hidden"
							onClick={() => {
								setActivadorSelect(!activadorSelect);
							}}
						>
							{botones.filtro}
							{
								<span
									className={`icon-select  text-24 ml-5 ${
										activadorSelect ? "" : "transform rotate-180"
									}`}
								></span>
							}
						</button>

						{/* FILTROS*/}
						<div
							className={`${
								isMobile
									? activadorSelect
										? "h-350  p-3"
										: "h-px"
									: "p-3  w-64 h-screen-80"
							}  caja-filtros scroll background-var3--bg`}
						>
							<FiltrosContainer
								{...props}
								setLibrosFiltrados={setLibrosFiltrados}
								librosFiltrados={librosFiltrados}
								setSchFilter={setSchFilter}
								textosFiltros={textosInterfaz.filtros}
								botonesFiltros={botones}
								alFiltrar={() => {
									setActivadorSelect(false);
									retornarAlPrincipio();
									setModoFiltro({ activado: true });
								}}
								alLimpiarFiltros={() => {
									setActivadorSelect(false);
									retornarAlPrincipio();
									setModoFiltro({ activado: false });
								}}
							/>
						</div>
					</div>

					<div className="w-full lg:ml-5 mt-5 lg:mt-0">
						<div
							className="columna-actual-seleccion flex flex-col center sm:flex-row sm:justify-between primary-var5--bg p-2 "
							ref={inicioListaRef}
						>
							{/* <h3 className="text-20  font-raleway font-bold text-morado text-center">{`${totalSeleccionados}/${maximaCantidadLibros} ${libros?.seleccionados || 'seleccionados'}`}</h3> */}
							<h2 className="primary-inv-var2--color font-raleway font-extrabold text-22">
								{cursoActual?.nombre || "N/A"}
							</h2>
							<p className="onbackground-var1--color font-quicksand font-bold text-20">{`${
								librosVisibles?.length
							}/${librosData?.libros?.length} ${
								sesion_activa?.libros_visibles || "Libros visibles"
							}`}</p>
						</div>
						<ListadoLibros
							{...props}
							setLibrosFiltrados={setLibrosFiltrados}
							libros={librosFiltrados}
							schFilter={schFilter}
							cursosData={compileCursosData()}
							analisisColeccion={AnalisisColeccion(librosFiltrados)}
							textosInterfaz={textosInterfaz}
							permitirMultiSeleccion={true}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SuscripcionActivaComponent;

SuscripcionActivaComponent.propTypes = {
	/**
	 * Función que compila la información de los cursos
	 */
	compileCursosData: PropTypes.func,
	/**
	 * Objeto con todas las sedes disponibles  y en su interior un arreglo los cursos pertenecientes
	 */
	cursosData: PropTypes.object,
	/**
	 * objeto con el id de la sede y curso actualmente seleccionado
	 */
	itemsActivos: PropTypes.shape({
		sede: PropTypes.string,
		curso: PropTypes.string,
	}),

	/**
	 * función que actualiza el estado del modo filtro de búsqueda, recibe un parametro booleano que indica si está en modo filtro o no.
	 */
	setModoFiltro: PropTypes.func,
	/**
	 * función que actualiza los libros filtrados por el filtro de búsqueda, recibe un parametro array que contiene los libros filtrados
	 */
	setLibrosFiltrados: PropTypes.func,
	/**
	 * Arreglo de los libros filtrados por el filtro de búsqueda.
	 */
	librosFiltrados: PropTypes.arrayOf(PropTypes.object),
	/**
	 * función que actualiza el filtro de busqueda por titulo.
	 */
	setSchFilter: PropTypes.func,
	/**
	 * texto actual del filtro de busqueda por titulo.
	 */
	schFilter: PropTypes.string,
	/**
	 * Función que retorna los filtros disponibles para la busqueda de libros.
	 */
	AnalisisColeccion: PropTypes.func,
	/**
	 * Objeto con los textos traducidos de la interfaz del componente.
	 */
	textosInterfaz: PropTypes.object,

	/**
	 * Objeto con todos los libros disponibles y su correspndiente estado con base al paquete actual.
	 */
	librosData: PropTypes.shape({
		libros: PropTypes.arrayOf(PropTypes.object),
	}),

	/**
	 * Objeto con funciones que gestionan el estado del multiselector y los libros.
	 */
	selectFunctions: PropTypes.object,
};

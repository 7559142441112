import { Icon } from "@iconify/react";

export const SplashScreen = ({ visible }) => {
	return (
		<div className={`splash-screen ${visible ? "" : "ocultar"}`}>
			<img
				className="logo"
				src={"/images/makemake-logo-principal.png"}
				alt="MakeMake splash screen"
			/>
			<Icon icon={"icomoon-free:spinner9"} className={"spinner"} />{" "}
		</div>
	);
};

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Funciones
import comprobarNombreRepetido from './../../utilities/function/comprobarNombreRepetido';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Componentes
import EditarCursoNuevoComponent from './../../components/cursos/EditarCursoNuevo';
import listarArray from '../../utilities/function/listarElementos/listarArray';
import listarObject from './../../utilities/function/listarElementos/listarObject';
import SkeletonCursosEditarNuevo from '../../components/cursos/SkeletonsCursos/SkeletonCursosEditarNuevo';
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';


const EditarCursoNuevo = (props) => {
  const { descartarCambios, isLoading, classes, isLoadingProfesores } = props;
  //Estados globales
  const state = useTrackedState();
  const { datosInstitucion, datosProfesores, temporal } = state;
  const dispatch = useDispatch();


  const history = useHistory();
  const params = useParams();
  const { curso_nuevo } = params;

  //Estados
  const [datosCursoNuevo, setdatosCursoNuevo] = useState([]);
  const [datosParaDescartar, setDatosParaDescartar] = useState({ icono: false, nombre: false, profesores: false, sede: false });
  const [iconoSeleccionado, setIconoSeleccionado] = useState(1);
  const [isCursoEliminado, setIsCursoEliminado] = useState(false);
  const [isNombreCursoRepetido, setIsNombreCursoRepetido] = useState(false);
  const [nombreCurso, setNombreCurso] = useState('');
  const [nombreCursoActual, setNombreCursoActual] = useState('');
  const [profesoresAsignados, setProfesoresAsignados] = useState({
    ids: [],
    datos: [],
  });
  const [profesoresInstituto, setProfesoresInstituto] = useState({ hayProfesores: null });
  const [sedeAnterior, setSedeAnterior] = useState(null);
  const [sedesInstituto, setSedesInstituto] = useState(null);
  const [sedeSeleccionada, setSedeSeleccionada] = useState({ id: null, nombre: '' })


  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('cursos');


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedesInstituto(listaSedes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!!temporal?.cursos) {
      if (!isCursoEliminado) {
        const datosCursosTemporal = { ...temporal.cursos.datos };
        let datosCurso = {};

        for (const key in datosCursosTemporal) {
          const curso = datosCursosTemporal[key];
          if (curso.key === curso_nuevo) {
            datosCurso = curso;
          }
        }

        setProfesoresAsignados({
          ids: datosCurso.datosProfesores,
          datos: datosCurso.profesores,
        })
        setIconoSeleccionado(datosCurso.icono);
        setNombreCurso(datosCurso.nombre);
        setNombreCursoActual(datosCurso.nombre);

        setSedeSeleccionada({ id: datosCurso.idSede, nombre: datosCurso.nombreSede })

        setSedeAnterior(datosCurso.idSede);
        setdatosCursoNuevo(datosCurso);
      }
    } else {
      history.push('/cursos');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal]);


  useEffect(() => {
    comprobarNombreCurso({ nombreCurso: nombreCurso });
    listarProfesoresInstituto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sedeSeleccionada.id])


  //Actualiza icono del curso
  const actualizarIconoCurso = ({ icono }) => {
    let validacionCampoIcono = false;
    if (icono !== datosCursoNuevo.icono) validacionCampoIcono = true;

    setDatosParaDescartar({ ...datosParaDescartar, icono: validacionCampoIcono });
    setIconoSeleccionado(icono)
  };


  //Habilita o deshabilita que se pueda subir un archivo
  const actualizarNombreCursos = ({ nombreCurso }) => {
    let validacionCampoNombre = false;
    if (nombreCurso !== datosCursoNuevo.nombre) validacionCampoNombre = true;

    setDatosParaDescartar({ ...datosParaDescartar, nombre: validacionCampoNombre });
    setNombreCurso(nombreCurso);
    comprobarNombreCurso({ nombreCurso });
  };


  //Actualiza el profesor asignado
  const actualizarProfesoresAsignados = ({ e, profesor }) => {
    const datosProfesoresAsignados = [...profesoresAsignados.datos];

    const resultados = elegirElementosCheck({ e, elemento: profesor, datosActuales: datosProfesoresAsignados });
    const { ids, datos } = resultados;

    setProfesoresAsignados({ids,datos});
  };


  //Actualiza la sede asignada
  const actualizarSedeAsignada = ({ idSede }) => {
    if (idSede !== sedeSeleccionada.id) {
      const nombreSede = datosProfesores.resultados[idSede].nombre;
      setSedeSeleccionada({
        id: idSede,
        nombre: nombreSede
      });

      setProfesoresAsignados({ ids: [], datos: [] });
    };
  };


  //Comprueba si el nombre ingresado corresponde a algun curso ya creado dentro de la sede
  const comprobarNombreCurso = ({ nombreCurso }) => {
    const sede = sedeSeleccionada.id;

    if (!!sede) {
      const array = classes[sede].cursos;
      const comprobacion = comprobarNombreRepetido({ array, nombreCurso, propiedad: sede });
      setIsNombreCursoRepetido(comprobacion.estaRepetida);
    }
  }


  //Editar curso  
  const editarCurso = () => {
    const cursosNuevos = { ...temporal.cursos.datos };
    let cursoActualizado = {};

    //Se actualizan los datos del curso nuevo
    for (const key in cursosNuevos) {
      let curso = cursosNuevos[key];
      if (datosCursoNuevo.key === curso.key) {
        cursoActualizado = {
          [curso.key]: {
            ...datosCursoNuevo,
            datosProfesores: profesoresAsignados.datos,
            icono: iconoSeleccionado,
            idSede: sedeSeleccionada.id,
            key: curso.key,
            nombre: nombreCurso,
            nombreSede: sedeSeleccionada.nombre,
            profesores: profesoresAsignados.ids
          }
        }
      }
    }

    //Se actualiza el arreglo de los nombre de los cursos nuevos, ya sea eliminandolo del curso anteior o actualizando el nuevo nombre
    let nombresCursos = { ...temporal.cursos.nombreCursos };
    nombresCursos[sedeAnterior].forEach((curso, index) => {
      if (curso.key === datosCursoNuevo.key) {
        if (sedeSeleccionada.id !== sedeAnterior) {
          nombresCursos[sedeAnterior].splice(index, 1);
        } else {
          nombresCursos[sedeAnterior][index] = {
            ...nombresCursos[sedeAnterior][index],
            nombre: datosCursoNuevo.nombre
          }
        }
      }
    })


    if (!!nombresCursos[sedeSeleccionada.id]) {
      nombresCursos[sedeSeleccionada.id].push({ key: datosCursoNuevo.key, nombre: datosCursoNuevo.nombre })
    } else {
      nombresCursos[sedeSeleccionada.id] = [{ key: datosCursoNuevo.key, nombre: datosCursoNuevo.nombre }]
    }

    //Se crean los nuevos datos de los cursos nuevos
    cursoActualizado = { ...cursosNuevos, ...cursoActualizado }
    const nuevosDatosTemporales = {
      ...temporal.cursos,
      datos: cursoActualizado,
      nombreCursos: nombresCursos
    }

    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "cursos",
      value: nuevosDatosTemporales
    });
  }


  //Eliminar curso
  const eliminarCurso = () => {
    const cursosTemporal = { ...temporal.cursos.datos };
    let cursosNuevos = cursosTemporal;
    delete cursosNuevos[datosCursoNuevo.key];

    const nuevosDatosTemporales = {
      ...temporal.cursos,
      datos: cursosNuevos
    }

    setIsCursoEliminado(true)
    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "cursos",
      value: nuevosDatosTemporales
    });
  };



  //Genera el listado de profesores que el profesor puede seleccionar para asignar al curso
  const listarProfesoresInstituto = () => {
    const profesoresFiltrados = listarObject({
      object: datosProfesores.resultados,
      idSede: sedeSeleccionada.id,
      propiedad: 'profesores'
    })

    const resultados = {
      hayProfesores: profesoresFiltrados.resultados.hayResultados,
      profesores: profesoresFiltrados.resultados.resultados,
    }

    setProfesoresInstituto(resultados);
  }


  return (
    <>
      {
        !isLoading && !isLoadingProfesores && isReady && !!profesoresInstituto.hayProfesores !== null && !!sedesInstituto && !!temporal ? (
          <>
            <EditarCursoNuevoComponent
              actualizarIconoCurso={actualizarIconoCurso}
              actualizarNombreCursos={actualizarNombreCursos}
              actualizarProfesoresAsignados={actualizarProfesoresAsignados}
              actualizarSedeAsignada={actualizarSedeAsignada}
              comprobarNombreCurso={comprobarNombreCurso}
              datosViejos={datosCursoNuevo}
              descartarCambios={descartarCambios}
              editarCurso={editarCurso}
              eliminarCurso={eliminarCurso}
              estudiantes={datosCursoNuevo.estudiantes}
              iconoSeleccionado={iconoSeleccionado}
              isCursoEliminado={isCursoEliminado}
              isNombreCursoRepetido={isNombreCursoRepetido}
              nombreCursoNuevo={nombreCurso}
              nombreCursoActual={nombreCursoActual}
              profesoresInstituto={profesoresInstituto}
              profesoresAsignados={profesoresAsignados}
              sedeSeleccionada={sedeSeleccionada}
              sedesInstituto={sedesInstituto}
              textosInterfaz={{
                botones: textosInterfaz.botones,
                curso_eliminado: textosInterfaz.curso_eliminado,
                descartar_cambios: textosInterfaz.descartar_cambios,
                editar: {
                  ...textosInterfaz.editar,
                  estudiantes: textosInterfaz.verificar_datos.estudiantes
                },
                eliminar_curso_temporal: textosInterfaz.eliminar_curso_temporal,
                miga_de_pan: textosInterfaz.miga_de_pan,
                modal_icono: textosInterfaz.modal_icono,
                notificacion: textosInterfaz.notificacion,
              }}
            />
          </>
        )
          : <SkeletonCursosEditarNuevo />
      }
    </>
  )
}

export default EditarCursoNuevo;
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';
//Store
import { useTrackedState, useDispatch } from '../../store';
//Componentes
import General from './General';
import CrearInstituto from './CrearInstituto';
import VerificarInstituto from './VerificarInstituto';
import EditarInstituto from './EditarInstituto';
import Error_404 from './../Error_404/';
//Hooks personalizados
import { useInstitutionQuery } from '../../hooks/api-queries/use-institution-query';


/**
 * Componente de tipo Container, es el componente principal del módulo de Institutos. Su función es renderizar el container  correspondiente y asignarle la información requerida. Los containers que pueden ser renderizados son General, CrearInstituto, VerificarInstituto o EditarInstituto. 
 */
const Institutos = () => {
  //Estados globales
  const state = useTrackedState();
  const { planActivo, datosInstitucion } = state;
  const dispatch = useDispatch();
  const institutionQuery = useInstitutionQuery(); 

  //Estados del componente
  const [isCrearInstituto, setIsCrearInstituto] = useState();

  //Otras librerias
  const history = useHistory();
  
  //Hooks personalizado para el llamado inicial de los datos en el container
  const isLoading = institutionQuery?.isLoading || institutionQuery.isFetching;

  useEffect(() => {
    
    return () => {
      borrarDatos()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!!datosInstitucion) {
      const institucionesCreadas = datosInstitucion.sedes.length;
      const createSede = institucionesCreadas < planActivo.numeroMaximoDeSedes ? true : false;

      setIsCrearInstituto(createSede)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosInstitucion])


  //Descartar cambios
  const descartarCambios = async () => {
    borrarDatos();
    history.push('/institutos')
  }

  //Descartar cambios
  const borrarDatos = async () => {
    let dataTemporal = {};

    await dispatch({
      type: "SET_DATA",
      property: "temporal",
      value: dataTemporal
    });
  }


  return (
    <Switch>
      <Route exact path='/institutos'>
        <General
          isCrearSede={isCrearInstituto}
          isLoading={isLoading}
        />
      </Route>
      <Route exact path='/institutos/crear-instituto'>
        <CrearInstituto
          descartarCambios={descartarCambios}
          isCrearSede={isCrearInstituto}
        />
      </Route>
      <Route exact path='/institutos/crear-instituto/verificar-instituto'>
        <VerificarInstituto
          descartarCambios={descartarCambios}
        />
      </Route>
      <Route exact path='/institutos/editar-instituto'>
        <EditarInstituto
          isLoading={isLoading}        
        />
      </Route>
      <Route exact path='/institutos/editar-instituto/:sede' component={EditarInstituto} />
      <Route component={Error_404} />
    </Switch>
  )
}

export default Institutos;
import { useEffect, useState } from 'react';
//Store
import { useTrackedState } from '../../store';
//Hooks y funciones personalizadas
import useObtenerTraducciones from '../helpers/hooks/useObtenerTraducciones';
//Componentes
import SideNavComponent from '../../components/sidenav';
import PropTypes from 'prop-types';

/**
 * Componente de tipo container que recibe la función y el estado del menú lateral. Este container renderiza el componente SideNavComponent, al cual se le pasan una serie de props que sirven para visualizar dicho componente. 
 * @returns {JSX.Element} SideNavComponent
 */
const SideNav = (props) => {
  const { abrirMenu, cerrarAbrirMenu } = props;
  // Estados globales
  const state = useTrackedState();
  const { planActivo, idioma } = state;
  const { tipoUsuario, eligiendoLibros, verProfesores, verClubs, verEstadisticas, verSedes } = planActivo;

  //Hooks personalizados
  const { isReady, textosSeccion: textosInterfaz } = useObtenerTraducciones('menu');
  const { isReady: isReadyEstudiantes, textosSeccion: textosInterfazEstudiantes } = useObtenerTraducciones('menuEstudiante');

  //Estados del componete
  const [itemsMenu, setItemsMenu] = useState([]);
  const [isEstudiante, setIsEstudiante] = useState(false);


  useEffect(() => {
    let items = [];
    let itemsMenu = tipoUsuario === 'estudiante' ? { ...textosInterfazEstudiantes } : { ...textosInterfaz };
    if (tipoUsuario !== 'estudiante') {
      const borrarPropiedad = (propiedad, condicion) => {
        if (!condicion) {
          delete itemsMenu[propiedad];
        }
      }
      borrarPropiedad('institutos', verSedes)
      borrarPropiedad('profesores', verProfesores)
      borrarPropiedad('clubs', verClubs)
      borrarPropiedad('estadisticas', verEstadisticas)
    } else {
      setIsEstudiante(true);
    }

    for (let i in itemsMenu) {
      const ruta = tipoUsuario === 'estudiante' ? `/estudiante/${i}` : `/${i}`
      items.push({
        nombre: itemsMenu[i],
        ruta: ruta,
        disabled: eligiendoLibros && i !== 'libros'
      })
    }
    setItemsMenu(items)

  }, [textosInterfaz, idioma, textosInterfazEstudiantes, tipoUsuario, verSedes, verProfesores, verClubs, verEstadisticas, eligiendoLibros])

  return (
    <>
      {isReady && isReadyEstudiantes &&
        <SideNavComponent
          abrirMenu={abrirMenu}
          itemsMenu={itemsMenu}
          isEstudiante={isEstudiante}
          cerrarAbrirMenu={cerrarAbrirMenu}
        />
      }
    </>
  )
}

export default SideNav;

SideNav.propTypes = {
  /**
   * Es un booleano que indica si el menú se encuentra abierto o cerrado.
   */
  abrirMenu: PropTypes.bool.isRequired,

  /**
    *  función que se encarga de cerrar o abrir el menú en la versión móvil de la aplicación. Este prop viene del componente APP
    * @type{Function(value : number ):void} -callback
    */
  cerrarAbrirMenu: PropTypes.func.isRequired,
}